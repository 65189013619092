$body: #09000b;
$opensans: "Open Sans", sans-serif;
$dark: #0a0a0a;


.container {
  padding: 100px 0;
}

.profile {
  min-width: 250px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  position: relative;
  text-align: left;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  transition: .3s ease-in-out all;

  h2{
    color: $dark;
    font-size: 23px;
    font-weight: 500;
    font-family: $opensans;
  }

  p{
    font-size: 17px;
    color: rgba($dark, 0.8);
    font-weight: 300;
    font-family: $opensans;
    position: relative;
  }

  .image{
    width: 75px;
    height: 75px;
    background: red;
    border-radius: 50%;
	  border: 3px solid #ffcc00;
    margin: 0 auto;
    position: absolute;
    right: 15px;
    top: 15px;
    transform-origin: bottom left;
    box-shadow: 0 3px 15px rgba($dark, 0.1);
    transition: all 0.3s ease-in-out;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }



  .profile_social_links .prof_soc_link{
	  color: black;
	  margin: 10px 10px 10px 0px;
  }

  .profile_social_links .prof_soc_link:hover{
	  color: #ffcc00;
	  transform: scaleX(1.1)
  }

  &:hover {
    .image {
      transform: scale(1.4);
      border-radius: 10px;
	    border: none;
    }
  }
}

.profile:hover{
	box-shadow: rgba(217, 220, 223, 0.774) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

// @media  screen and (max-width:772px) {
//   .profile{
//     width: 250px;
//   }
// }


