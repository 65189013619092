.company_division{
    background-color: rgb(249, 249, 249);
    margin: 10px 0px;
    text-align: left;
    padding: 20px;
    box-sizing: border-box;
}

#heading_ternary{
    font-family: "Open Sans", sans-serif;
    font-size: 28px;
    font-weight: 700;
}

#desc_ternary{
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 200;
}