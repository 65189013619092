@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');
.home{
    position: relative;
    overflow-x: hidden;
}

.home_navbar{
    position: absolute;
    top: 0;
    background-color: transparent;
    z-index: 1000;
    width: 100vw;
    display: flex;
    flex-direction: row;
    height: 80px;
    box-sizing: border-box;
    color: white;
    transition: 0.3s ease-in-out all;
}

.home_navbar_sticky{
    position: fixed;
    background-color: white;
    color: black;
}

.home_navbar:hover{
    background-color: white;
    color: black;
}

.home_navbar:hover .home_navbar_container .home_navbar_options .home_navbar_options_list > .home_navbar_link{
    color: black;
}


.home_navbar_container{
    width: 90%;
    padding: 5px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    box-sizing: border-box;
}

.home_navbar .home_navbar_container .home_navbar_logo{
    display: flex;
    width: 40%;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    height: 60px;
    font-size: 22px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0%;
}

.home_navbar .home_navbar_container .home_navbar_logo .logo_img{
    height: 100%;
    width: 20%;
    object-fit: contain;
}

.home_navbar .home_navbar_container .home_navbar_options{
    width: 60%;
}

.home_navbar .home_navbar_container .home_navbar_options .home_navbar_options_list{
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-evenly;
    align-items: center;
    height: 60px;
}

.home_navbar .home_navbar_container .home_navbar_options .home_navbar_options_list > .home_navbar_link{
    list-style: none;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 13px;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    transition: 0.2s ease-in-out all;
    color: white;
    text-transform: uppercase;
}

.home_navbar_link_sticky{
    list-style: none;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 13px;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    transition: 0.2s ease-in-out all;
    text-transform: uppercase;
    color: black;
}

.home_navbar .home_navbar_container .home_navbar_options .home_navbar_options_list > .home_navbar_link::before{
    content: "";
    position: absolute;
    width: 50%;
    border-bottom:5px solid #ffcc00 ;
    bottom: -50%;
    transition: 0.3s ease-in-out all;
    transition: .2s ease-in-out all;
    opacity: 0;
}

.home_navbar .home_navbar_container .home_navbar_options .home_navbar_options_list > .home_navbar_link::after{
    content: "";
    position: absolute;
    width: 50%;
    border-bottom:5px solid #333333 ;
    bottom: -50%;
    transform: translateX(-93%);
    transition: .2s ease-in-out all;
    opacity: 0;

}

.home_navbar .home_navbar_container .home_navbar_options .home_navbar_options_list > .home_navbar_link_sticky::before{
    content: "";
    position: absolute;
    width: 50%;
    border-bottom:5px solid #ffcc00 ;
    bottom: -50%;
    transition: 0.3s ease-in-out all;
    transition: .2s ease-in-out all;
    opacity: 0;
}

.home_navbar .home_navbar_container .home_navbar_options .home_navbar_options_list > .home_navbar_link_sticky::after{
    content: "";
    position: absolute;
    width: 50%;
    border-bottom:5px solid #333333 ;
    bottom: -50%;
    transform: translateX(-93%);
    transition: .2s ease-in-out all;
    opacity: 0;

}

.home_navbar .home_navbar_container .home_navbar_options .home_navbar_options_list > .home_navbar_link_active::before{
    content: "";
    position: absolute;
    width: 50%;
    border-bottom:5px solid #ffcc00 ;
    bottom: -50%;
    transition: 0.3s ease-in-out all;
    transition: .2s ease-in-out all;
    opacity: 1;
}

.home_navbar .home_navbar_container .home_navbar_options .home_navbar_options_list > .home_navbar_link_active::after{
    content: "";
    position: absolute;
    width: 50%;
    border-bottom:5px solid #333333 ;
    bottom: -50%;
    transform: translateX(-93%);
    transition: .2s ease-in-out all;
    opacity: 1;

}

.home_navbar .home_navbar_container .home_navbar_options .home_navbar_options_list > .home_navbar_link:hover::before{
    opacity: 1;
}

.home_navbar .home_navbar_container .home_navbar_options .home_navbar_options_list > .home_navbar_link:hover::after{
    opacity: 1;
}

.home_navbar .home_navbar_container .home_navbar_options .home_navbar_options_list > .home_navbar_link_sticky:hover::before{
    opacity: 1;
}

.home_navbar .home_navbar_container .home_navbar_options .home_navbar_options_list > .home_navbar_link_sticky:hover::after{
    opacity: 1;
}

.home_navbar .home_navbar_container .home_navbar_options .home_navbar_options_list > .home_navbar_link:hover{
    color: #ffcc00;
}
.home_navbar .home_navbar_container .home_navbar_options .home_navbar_options_list > .home_navbar_link_sticky:hover{
    color: #ffcc00;
}

.home_navbar_side_menu_options{
    display: none;
}


.home_navbar_hamburger_icon{
    display: none;
    cursor: pointer;
}

.home .description1_cont{
    background-color: #ffcc00;
}

.home .description1{
    display: flex;
    flex-direction: row;
    height: max-content;
    max-width: 90%;
    margin: auto;
    padding-left: 30px;
    padding-right: 30px;
    height: 80vh;
    justify-content: space-between;
}

.home .description2{
    display: flex;
    flex-direction: row;
    height: max-content;
    max-width: 90%;
    margin: auto;
    overflow-x: hidden;
    justify-content: space-between;
    height: 80vh;
}

.home .description1 .left_description1{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.home .description1 .right_description1{
    width: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home .description2 .left_description2{
    width:65%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.home .description2 .right_description2{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home .description1 .left_description1 h1{
    font-family: "Open Sans", sans-serif;
	font-size: 55px; 
    font-weight: 700;
    text-align: right;  
    position: relative;
}

.home .description1 .left_description1 h1::after{
    content: "";
    width: 100%;
    border: 2px solid white;
    position: absolute;
    bottom: -3%;
    left: 0;
}

.home .description1 .right_description1 img{
    width: 100%;
    object-fit: contain;
    border-radius: 3%;
}

.home .description2 .left_description2 img{
    width: 100%;
    object-fit: contain;
    border-radius: 3%;
   
}

.home .description2 .right_description2 h1{
    font-family: "Open Sans", sans-serif;
	font-size: 55px; 
    font-weight: 700;
    text-align: left;
    position: relative;
}

.home .description2 .right_description2 h1::after{
    content: "";
    width: 100%;
    border: 2px solid #ffcc00;
    position: absolute;
    bottom: -5%;
    left: 0;
}

.home .phone_view{
    display: hidden;
}

.home .desktop_view{
    display: block;
}


@media screen and (max-width:820px){
    .home_navbar_container{
        justify-content: space-between;
        z-index: 100;
    }
    .home_navbar_options{
        display: none;
    }

    .home_navbar_sticky{
        position: fixed;
    }

    .home_navbar_side_menu_options{
        border-top: 3px solid #ffcc00;
        display: flex;
        background-color: #222222;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        transform: translateY(0%);
        overflow-y: hidden;
        height: max-content;
        display: flex;
        flex-direction: column;
        text-align: left;
        transition: 0.4s ease-in-out all;
        opacity: 0;
        visibility: hidden;
        box-sizing: content-box;
    }
    

    .home_navbar_side_menu_options .home_navbar_link{
        text-decoration: none;
        color: white;
        padding: 17px 30px;
        transition: 0.3s ease-in-out all;
        border-bottom: 0.5px solid rgba(146, 146, 146, 0.425);
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        font-size: 14px;
        text-transform: uppercase;
    }
    .home_navbar .home_navbar_container .home_navbar_logo .logo_img{
        height: 100%;
        width: 33%;
        object-fit: contain;
    }

    .home_navbar_side_menu_options .home_navbar_link_sticky:hover{
        color: #ffcc00;
        background-color: #1d1d1d93;
        border-bottom: 0.5px solid rgba(255, 204, 0, 0.692);
    }
    .home_navbar_side_menu_options .home_navbar_link_sticky{
        text-decoration: none;
        color: white;
        padding: 17px 30px;
        transition: 0.3s ease-in-out all;
        border-bottom: 0.5px solid rgba(146, 146, 146, 0.425);
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        font-size: 14px;
    }

    .home_navbar_side_menu_options .home_navbar_link:hover{
        color: #ffcc00;
        background-color: #1d1d1d93;
        border-bottom: 0.5px solid rgba(255, 204, 0, 0.692);
    }

    .home_navbar_side_menu_options_active{
        visibility: visible;
        transform: translateY(80px);
        opacity: 1;
    }

    .home_navbar_hamburger_icon{
        display: flex;
        align-items: center;
        z-index: 2;
    }

    

    .desktop_view{
        display: hidden;
    }

    .phone_view{
        display: contents;
    }

    .home .description1{
        display: flex;
        flex-direction: column;
        height: max-content;
        max-width: 100vw;
        justify-content:center;
        padding: 10px;
        height: 80vh;
    }
    
    .home .description2{
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        max-width: 100vw;
        overflow-x: hidden;
        justify-content: center;
        padding: 10px;
        height: 80vh;
    }
    
    .home .description1 .left_description1{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .home .description1 .right_description1{
        width: 100%;
    }
    
    .home .description2 .left_description2{
        width:100%;
    }
    .home .description2 .right_description2{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .home .description1 .left_description1 h1{
        font-family: 'Poppins', sans-serif;
        font-weight: 800;
        font-size: 40px;   
    }
    
    .home .description1 .right_description1 img{
        height: 100%;
        object-fit: contain;
    }
    
    .home .description2 .left_description2 img{
        height: 100%;
        object-fit: contain;
       
    }
    
    .home .description2 .right_description2 h1{
        font-family: 'Poppins', sans-serif;
        font-weight: 800;
        font-size: 40px;
    }
}
