  .slideshow {
	margin: 0 auto;
	overflow: hidden;
	width: 100vw;
	position: relative;
	height: 100vh;
  }
  
  .slideshowSlider {
	white-space: nowrap;
	transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  
  .slide {
	display: flex;
	height: 100vh;
	width: 100%;
	justify-content: center;
	align-items: center;
	background-size: cover;
	position: relative;
  }

  .slide .slide_content{
	  position: absolute;
	  top: 82%;
	  left: 50%;
	  transform: translateX(-50%) translateY(-40%);
	  z-index: 2;
	  width: 55%;
  }

  .slide #slide_heading{
	  color: white;
	  font-family: "Open Sans", sans-serif;
	  font-size: 38px; 
	  text-transform: uppercase;
	  width: 100%;
	  font-weight: 700;
	  word-wrap: break-word;
	  white-space: initial;
	  position: relative;
  }

  .slide #slide_heading::after{
	  content: "";
	  position: absolute;
	  width: 100%;
	  border-bottom: 2px solid white;
	  left: 0;
	  bottom: 0;
  }
  

  .slide::after{
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.3)
  }
  
  .slideshowDots {
	text-align: center;
	position: absolute;
	top: 50%;
	left: 2%;
	transform: translateY(-50%);
	margin-bottom: 15px;
  }
  
  .slideshowDot {
	display: flex;
	height: 10px;
	width: 10px;
	border-radius: 20px;
	cursor: pointer;
	margin: 15px 7px 0px;
	background-color: #c4c4c4;
	box-sizing: border-box;
	transition: 0.3s ease all;
	z-index: 2;
  }
  
  .slideshowDot.active {
	background-color: #ffcc00;
	height: 60px;
	border-radius: 10px;
  }

  #carousel_arrow{
	  position: absolute;
	  bottom: 1%;
	  left: 50%;
	  transform: translateX(-50%);
	  animation: MoveUpDown 1.2s linear infinite;
  }

  @keyframes MoveUpDown {
	0%,100%{
	  bottom: 1%;
	}
	50% {
	  bottom: 0%;
	}
  }

  @media screen and (max-width:540px){
	.slide .slide_content{
		width: 75%;
	}
  
	.slide #slide_heading{
		font-size: 30px; 
	}
  }
  @media screen and (max-width:440px){
  
	.slide #slide_heading{
		font-size: 26px; 
	}
  }
