.menu{
    width: 100vw;
    box-sizing: border-box;
    height: 80px;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position:sticky;
    top: 0;
    z-index: 1000;
    background-color: white;
}



.menu_container{
    max-width: 85%;
    padding: 5px 20px;
    display: flex;
    flex-direction: row;
    margin: auto;
}

.menu .menu_container .logo{
    display: flex;
    width: 40%;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    height: 60px;
    font-size: 22px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0%;
}

.menu .menu_container .logo .logo_img{
    height: 100%;
    width: 20%;
    object-fit: contain;
}

.menu .menu_container .menu_options{
    width: 60%;
}

.menu .menu_container .menu_options .menu_options_list{
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-evenly;
    align-items: center;
    height: 60px;
}

.menu .menu_container .menu_options .menu_options_list > .opt_menu_link{
    list-style: none;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 13px;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    color: black;
    transition: 0.2s ease-in-out all;
    text-transform: uppercase;
}
.menu .menu_container .menu_options .menu_options_list > .opt_menu_link::before{
    content: "";
    position: absolute;
    width: 50%;
    border-bottom:5px solid #ffcc00 ;
    bottom: -50%;
    transition: 0.3s ease-in-out all;
    transition: .2s ease-in-out all;
    opacity: 0;
}

.menu .menu_container .menu_options .menu_options_list > .opt_menu_link::after{
    content: "";
    position: absolute;
    width: 50%;
    border-bottom:5px solid #333333 ;
    bottom: -50%;
    transform: translateX(-93%);
    transition: .2s ease-in-out all;
    opacity: 0;

}
.menu .menu_container .menu_options .menu_options_list > .opt_menu_link_active::before{
    content: "";
    position: absolute;
    width: 50%;
    border-bottom:5px solid #ffcc00 ;
    bottom: -50%;
    transition: 0.3s ease-in-out all;
    transition: .2s ease-in-out all;
    opacity: 1;
}

.menu .menu_container .menu_options .menu_options_list > .opt_menu_link_active::after{
    content: "";
    position: absolute;
    width: 50%;
    border-bottom:5px solid #333333 ;
    bottom: -50%;
    transform: translateX(-93%);
    transition: .2s ease-in-out all;
    opacity: 1;

}

 .menu .menu_container .menu_options .menu_options_list > .opt_menu_link:hover::before{
    opacity: 1;
}

.menu .menu_container .menu_options .menu_options_list > .opt_menu_link:hover::after{
    opacity: 1;
}

.menu .menu_container .menu_options .menu_options_list > .opt_menu_link:hover{
    color: #ffcc00;
}

.menu .menu_container .menu_options .menu_options_list .products #products_desc{
    position: absolute;
    display: none;
    text-align: left;
    z-index: 1;
    left: 0;
    top: 100%;
    transition: 5.4s ease-in all;
    background-color: #333333;
    border-top: 3px solid #ffcc00;
}

.menu .menu_container .menu_options .menu_options_list .products:hover #products_desc{
    display: block;
}

.side_menu_options{
    display: none;
}

#products_desc div {
    width: max-content;
    left: 0;
    z-index: 110;
}

#products_desc div #opt2{
    display: block;
    font-family: "Open Sans", sans-serif;
    border-bottom: 0.1px solid rgba(146, 146, 146, 0.425);
    padding: 5px;
    text-decoration: none;
    padding: 10px;
}

#opt2{
    color: white;
    background-color: #333333;
    transition: 0.3s ease-in-out all;
}

#opt2:hover{
    background-color:#ffcc00;
    color: black;
}

.hamburger_icon{
    display: none;
    cursor: pointer;
}

@media screen and (max-width:772px){
    .menu_container{
        justify-content: space-between;
        z-index: 100;
    }
    .menu_options{
        display: none;
    }

    .menu .menu_container .logo .logo_img{
        height: 100%;
        width: 33%;
        object-fit: contain;
    }
    

    .side_menu_options{
        border-top: 3px solid #ffcc00;
        display: flex;
        background-color: #222222;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        transform: translateY(0%);
        overflow-y: hidden;
        height: max-content;
        display: flex;
        flex-direction: column;
        text-align: left;
        transition: 0.4s ease-in-out all;
        opacity: 0;
        visibility: hidden;
        box-sizing: content-box;
    }
    

    .side_menu_options .opt_menu_link{
        text-decoration: none;
        color: white;
        padding: 17px 30px;
        transition: 0.3s ease-in-out all;
        border-bottom: 0.5px solid rgba(146, 146, 146, 0.425);
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        font-size: 13px;
        text-transform: uppercase;
    }

    .side_menu_options .opt_menu_link:hover{
        color: #ffcc00;
        background-color: #1d1d1d93;
        border-bottom: 0.5px solid rgba(255, 204, 0, 0.692);
    }

    .side_menu_options_active{
        visibility: visible;
        transform: translateY(80px);
        opacity: 1;
    }

    .hamburger_icon{
        display: flex;
        align-items: center;
        z-index: 2;
    }
}