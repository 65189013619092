.parallax{
    height: 30vh;
    position: relative;
}

.parallax::after{
    position: absolute;
    content: "";
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 2;
}

.parallax #parallax_heading{
    position: absolute;
    color: white;
    width: 85%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    right: auto;
    text-align: left;
    z-index: 3;
    font-family: "Open Sans", sans-serif;
    font-size: 60px;

}

@media  screen and (max-width:420px) {
    .parallax #parallax_heading{
        font-size: 55px;
    }
}
@media  screen and (max-width:380px) {
    .parallax #parallax_heading{
        font-size: 48px;
    }
}