.products_page{
    width: 100vw;
}

.products_page_container{
    width: 90vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px 0;
}

.products_cont_left{
    width: 100%;
    position: relative;
    text-align: left;
    min-height: 100vh;
    height: max-content;
    margin: auto;
}

.products_cont_left .gallery{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}


.prod_pagination_menu{
    display: flex;
    flex-direction: column;
    width: 25%;
}

.prod_pagination_menu .prod_page_link{
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    padding: 15px 20px;
    margin: 2px;
    text-align: left;
    background-color: rgb(240, 240, 240);
    text-decoration: none;
    color: black;
    font-weight: 700;
    transition: 0.3s ease-in-out all;
}

.prod_pagination_menu .prod_page_link:hover{
    background-color: rgb(247, 247, 247);
}

.prod_pagination_menu .prod_page_link_active{
    border-left: 5px solid #ffcc00;
}

@media screen and (max-width:772px) {
    .products_page_container{
        flex-direction: column;
    }

    .prod_pagination_menu{
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-flow: wrap;
        justify-content: center;
    }
    .prod_pagination_menu .prod_page_link{
        border-bottom: 5px solid transparent;
    }
    .prod_pagination_menu .prod_page_link_active{
        border-left: none;
        border-bottom: 5px solid #ffcc00;
    }

    .products_cont_left{
        width: 100%;
    }
    
}

@media screen and (max-width:540px) {
    .prod_pagination_menu{
        display: flex;
        flex-direction: row;
        min-width: 100%;
        flex-flow: unset;
        justify-content: center;
        overflow-x: scroll;
        scroll-behavior: smooth;
    }
    .prod_pagination_menu .prod_page_link{
       width: 500px;
    }
}