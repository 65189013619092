.wwd_cards{
    display: flex;
    flex-direction: column;
    height: 200px;
    width: 45%;
    background-color: white;
    margin: 10px;
    object-fit: contain;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    padding: 15px;
    align-items: center;
    justify-content: center;
}

#wwd_logo{
    background-color: #ffcc00;
    padding: 20px;
    width: fit-content;
}

#wwd_heading{
    font-family: "Open Sans", sans-serif;
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    width: fit-content;
    text-align: center;
    margin: 10px 0;
}

#wwd_desc{
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 300;
    width: fit-content;
    text-align: left;
    margin-bottom: 40px;
}

@media screen and (max-width:1024px) {
    .wwd_cards{
        width: 44%;
    }
}
@media screen and (max-width:920px) {
    .wwd_cards{
        width: 30%;
    }
}

@media screen and (max-width:772px) {
    .wwd_cards{
        width: 80%;
    }
}