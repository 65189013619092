.about{
    max-width: 100vw;
    padding-bottom: 50px;
    box-sizing: border-box;
    overflow-y: scroll;
}

.about .about_content{
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: row;
    padding: 30px;
    justify-content: space-between;
}

.about .about_content .left_side_abt{
    display: flex;
    flex-direction: column;
    flex: 0.7;
    position: relative;
}

.about .about_content .left_side_abt::before{
    position: absolute;
    content: "";
    border-right: 1px solid rgba(146, 146, 146, 0.425);
    width: 100%;
    height: 90%;
    transform: translateY(5%);
    left: 5%;
}

.about .about_content .left_side_abt #about_cnt_desc{
    font-family: "Open Sans", sans-serif;
    text-align: left;
    font-size: 15px;
    font-weight: 300;
}

.slider{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.slides{
    width: 500%;
    height: 100%;
    display: flex;
}
input{
    display: none;
}

.slide{
    width: 20%;
    transition: 2s;
}
img{
    width: 100%;
    background-size: cover;
}

.navigation-manual{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}
.manual-btn{
    border: 3px solid #ffcc00;
    padding: 5px;
    border-radius: 50%;
    transition: 2s;
    cursor: pointer;
    outline: none;
    user-select: none;
}
.manual-btn:not(:last-child){
    margin-right: 30px;
}
.manual-btn:hover{
    background: #ffcc00;
    border: 3px solid black;
}


#radio1:checked ~ .first{
    margin-left: 0;
}
#radio2:checked ~ .first{
    margin-left: -20%;
}
#radio3:checked ~ .first{
    margin-left: -40%;
}
#radio4:checked ~ .first{
    margin-left: -60%;
}

.about .about_content .right_side_abt{
    display: flex;
    flex-direction: column;
    flex: 0.23;
    height: max-content;
    border: 2px solid #ffcc00;
    padding: 20px;
}

.about .about_content .right_side_abt .close_icon{
    margin-left: auto;
    display: none;
}

.about .about_content .right_side_abt .brochure_open{
    display: block;
}



.about .about_content .right_side_abt #brochure_heading{
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 700;
    color: gray;
    text-align: left;
    text-transform: uppercase;
}

.about .about_content .right_side_abt #brochure_desc{
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: gray;
    font-weight: 600;
    text-align: left;
}

.about .about_content .right_side_abt #brochure_link{
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: gray;
    border: 2px solid #ffcc00;
    padding: 15px 0px;
    border-radius: 100px;
    font-weight: 800;
    text-decoration: none;
    display:"flex";
    flex-direction:"row";
    align-items:"center";
    transition: 0.3s ease-in-out all;
}

.about .about_content .right_side_abt #brochure_link:hover{
    background-color: #ffcc00;
    color: black;
}

#heading_secondary{
    font-family: "Open Sans", sans-serif;
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    width: fit-content;
    text-align: center;
    margin: 20px 0px 50px 0px;
}

#heading_secondary::before{
    content: "";
    position: absolute;
    width: 25%;
    border-bottom: 10px solid #333333;
    bottom: -50%;
    transform: translateX(0%);
}

#heading_secondary::after{
    content: "";
    position: absolute;
    width: 25%;
    border-bottom: 10px solid #ffcc00;
    bottom: -50%;
    transform: translateX(-300%);
}

#prod_range_list{
    list-style: none;
}

#prod_range_list p{
   font-family: "Open Sans", sans-serif;
}

#prod_range_list p span{
    border-bottom: 2px solid #ffcc00;
    font-weight: 600;
}

.about .why_choose{
    width: 90%;
    margin: auto;
    text-align: left;
    padding: 30px;
}

.about .why_choose #desc_ternary{
    font-family: "Open Sans", sans-serif;
    text-align: left;
    font-size: 15px;
}

.about .why_choose .bullet_head{
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    word-spacing: 1px;
    font-size: 13px;
}

@media screen and (max-width:950px) {
    #heading_secondary{
        font-size: 22px;
    }
}

@media  screen and (max-width:772px) {
    .about{
        width: 100vw;
        position: relative;
    }

    .about_content{
        position: relative;
    }

    .about .about_content .left_side_abt{
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .about .about_content .left_side_abt::before{
        display: none;
    }

    .about .about_content .right_side_abt{
        position: absolute;
        width: 300px;
        left: 0;
        top: 50%;
        background-color: #ffcc00;
        border-radius: 50px;
        transform: translate(-95%, 50%);
        transition: 0.3s ease-in-out all;
        cursor: pointer;
    }

    .about .about_content .open_brochure #brochure_heading{
        color: black;
    }

    .about .about_content .open_brochure #brochure_desc{
        color: black;
    }

    .about .about_content .open_brochure #brochure_link{
        color: black;
        border: 2px solid black;
    }

    .about .about_content .open_brochure #brochure_link:hover{
        background-color: black;
        color: #ffcc00;
    }

    .about .about_content .open_brochure{
        border-radius: 0;
        position: absolute;
        left: -0%;
        top: 50%;
        transform: translate(0%, 50%);
        color: black;
        cursor: pointer;
    }

    #heading_secondary{
        font-size: 26px;
    }
}
@media  screen and (max-width:400px) {
    .about{
        width: 100vw;
    }

    .about .about_content .left_side_abt #about_cnt_desc{
        font-family: "Open Sans", sans-serif;
        text-align: left;
        font-size: 15px;
        font-weight: 300;
    }

    #heading_secondary{
        font-family: "Open Sans", sans-serif;
        font-size: 25px;
        font-weight: 700;
        text-transform: uppercase;
        position: relative;
        width: fit-content;
        text-align: center;
        margin: 20px 0px 50px 0px;
    }

    .about .about_content .right_side_abt{
        width: 210px;
    }

    .about .about_content .right_side_abt #brochure_heading{
        font-size: 16px;
    }
    
    .about .about_content .right_side_abt #brochure_link{
        padding: 10px 0px;
        border-radius: 100px;
    }

    
}