.gallery {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33.3%;
    margin: auto;
    /* padding-bottom: 3%; */
  }

  .products_cont_left{
    width: 90%;
    margin: auto;
  }

  .pics{
    
    background-color: transparent;
    /* background-color: #f9224b; */
  }
  
  .text {
    flex-wrap: wrap;
    flex-direction: column;
    -moz-box-align: end;
  }
  
  .title {
    padding: 0%;
    margin: 0%;
    margin-top: 0%;
  }
  .gallery .pics {
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
  }
  .gallery .pics :hover {
    /* opacity: 1; */
    transform: scale(0.9);
    transition-timing-function: ease;
    overflow: hidden;
  }
  
  @media (max-width: 990px) {
    .gallery {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      margin: auto;
    }
  }
  @media (max-width: 480px) {
    .gallery {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
      -webkit-column-width: 100%;
      -moz-column-width: 100%;
      column-width: 100%;
      margin: auto;
    }
  }
  .model {
    width: 100%;
    height: 91%;
    border-top: 1px solid black;
    position: fixed;
    bottom: 0%;
    left: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 1005;
    background-color: rgba(255, 255, 255, 1);
    /* backdrop-filter: blur(2.5rem); */
  }
  .open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    margin: auto;
  }
  
  .model .clickImg {
    width: 90%;
    height: 80%;
    object-fit: contain;
    border-radius: 10%;
    align-items: center;
    margin: auto;
  }
  .open svg {
    position: fixed;
    top: 2.6rem;
    right: 1.5rem;
    width: 60px;
    height: 60px;
    padding: 8px;
    border-radius: 50%;
    color: black;
    cursor: pointer;
    border: 3px solid black;
    transform: scale(0.9);
    transition: ease-in all 0.3s;
  }

  .open svg:hover{
      transform: scale(0.8);
  }
  .parent .title {
    font-family: "Teko", sans-serif;
    font-size: 62px;
    margin-left: 8%;
    margin-right: 8%;
    text-align: left;
  }
  .model .detail {
    font-family: New Century Schoolbook, serif;
    align-items: center;
    display: wrap;
    margin: 5%;
    margin-right: 10%;
    border-block: 10px;
    box-sizing: content-box;
    /* overflow: hidden; */
  }
  @media screen and (max-width: 1000px) {
    .model {
      flex-flow: row wrap;
    }
    .model .clickImg {
      display: block;
      order: 1;
      /* width: auto;
  height: auto; */
    }
    .model .clickImg {
      display: block;
      order: 2;
      width: 50%;
      align-items: center;
      /* margin-left: 25%;
  margin-top: 0%; */
      margin: auto;
    }
    .model .clickImg .detail {
      padding: 2%;
    }
    .model .clicImg .detail {
      margin-bottom: 2%;
    }
    .clicImg {
      align-items: center;
      margin-left: 15%;
    }
  }
  
  @media (max-width: 768px) {
    .model .clickImg {
      width: 100%;
      object-fit: contain;
      border-radius: 10%;
      align-items: center;
      margin: auto;
    }
  
    .parent {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top center;
      background-color: black;
      padding-top: 9em;
    }
  
    .waveUp {
      display: none;
    }
  }