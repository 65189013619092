.contact_us{
    min-height: 100vh;
    box-sizing: border-box;
}
.contact_us .contact_us_container{
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 50px;
}

.head_officials_container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.head_officials_container .official_profile{
    width: 45%;
    margin-top: 25px;
    margin-bottom: 25px;
}

.sec_one{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 40px;
   
}

.sec_one_left{
    width: 60%;
    position: relative;
}

.sec_one_left::before{
    position: absolute;
    content: "";
    height: 90%;
    transform: translateY(5%);
    right: -7.5%;
    border-right: 1px solid rgba(146, 146, 146, 0.425);
}

.sec_one_right{
    padding: 10px;
    width: 30%;
}
.sec_two{
    background-color: rgb(249, 249, 249);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 40px;
}

.contact_us .contact_us_container #heading_secondary{
    color: black;
    position: relative;
    width: fit-content;
    margin-bottom: 60px;
}

#heading_secondary_con{
    font-family: "Open Sans", sans-serif;
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    width: fit-content;
    text-align: center;
    margin-bottom: 40px;
}

#heading_primary{
    font-family: "Open Sans", sans-serif;
    font-size: 38px;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    width: fit-content;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    
}

.contact_us .contact_us_container #heading_secondary_con::before{
    content: "";
    position: absolute;
    width: 25%;
    border-bottom: 10px solid #333333;
    bottom: -50%;
    transform: translateX(100%);
}

.contact_us .contact_us_container #heading_secondary_con::after{
    content: "";
    position: absolute;
    width: 25%;
    border-bottom: 10px solid #ffcc00;
    bottom: -50%;
    transform: translateX(-200%);
}

.contact_us .contact_us_container #heading_secondary::before{
    content: "";
    position: absolute;
    width: 25%;
    border-bottom: 10px solid #333333;
    bottom: -50%;
    transform: translateX(0%);
}

.contact_us .contact_us_container #heading_secondary::after{
    content: "";
    position: absolute;
    width: 25%;
    border-bottom: 10px solid #ffcc00;
    bottom: -50%;
    transform: translateX(-300%);
}

@media screen and (max-width:1024px) {
    #heading_primary{
        font-size: 32px;
        text-align: left;
    }

    #heading_secondary{
        font-size: 26.1px;
    }
}

@media screen and (max-width:820px) {

    #heading_primary{
        font-size: 28px;
        text-align: left;
    }
    .contact_us{
        max-width: 100vw;
        overflow-x: hidden;
    }
    .contact_us .contact_us_container{
        padding-bottom: 0;
        width: 100%;
    }
    .sec_one{
        flex-flow: wrap
    }

    .sec_one_left{
        width: 100%;
    }

    .sec_one_left::before{
        display: none;
    }

    .sec_one_right{
        width: 100%;
    }

    .sec_two{
        background-color: rgb(249, 249, 249);
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        padding: 40px 20px;
    }

    .head_officials_container{
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        box-sizing: border-box;
    }

    .head_officials_container .official_profile{
        width: 100%;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    @media screen and (max-width:400px) {
        #heading_secondary{
            font-size: 25px;
        }
    }

}
