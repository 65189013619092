.products_card{
    width: 330px;
    height: 300px;
    position: relative;
    overflow-y: hidden;
    box-sizing: border-box;
    margin: 15px;
    border-radius: 8px ;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.products_card:hover .front_face{
    top: 0;
}

.back_face{
    width: 100%;
    height:100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: rgb(249, 249, 249);
}

.front_face{
    width: 100%;
    height:100%;
    position: absolute;
    background-color: #333333ee;
    top: 80%;
    transition: 0.4s ease-in-out all;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 25px;
}

.front_face #front_face_head{
    font-family: "Open Sans", sans-serif;
    font-size: 23px;
    color: white;
    position: relative;
}

.front_face #front_face_desc{
    font-family: "Open Sans", sans-serif;
    text-align: left;
    color: white;
    font-size: 15px;
    word-spacing: 2px;
    letter-spacing: 0.5px;
}

.front_face #front_face_link{
    color: #ffcc00;
    text-decoration: none;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s ease-in all;

}

.front_face #front_face_link:hover{
    color: white;
}

.front_face #front_face_link:hover #arrow_tran{
    margin-left: 4px;
    color: white;
}

.front_face #front_face_head::before{
    content: "";
    position: absolute;
    top: -10%;
    border-top: 3px solid #ffcc00;
    width: 45%;
    
}

.back_face img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media screen and (max-width:772px) {
    .products_card{
        width: 320px;
        height: 250px;
        position: relative;
        overflow-y: hidden;
        box-sizing: border-box;
        margin: 15px 0px;
    }
}