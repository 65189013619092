.footer{
    width: 100vw;
    display: flex;
    flex-direction: column;
    position: relative;
}

.footer_container_top{
    width: 100%;
    background-color: rgb(34, 34, 34);
    position: relative;
}
.footer_container_bottom{
    width: 100%;
    background-color: #333333;
}

#footer_top_bar{
    max-width: 85%;
    margin: auto;
    display: flex;
    flex-direction: row;
    padding: 20px;
    box-sizing: content-box;
}
#footer_bottom_bar{
    max-width: 85%;
    margin: auto;
    padding: 15px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer_top_bar_left{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-flow: wrap;
    justify-content: space-between;
    position: relative;
}


#footer_products{
   width: 28%;
   padding: 20px;
   position: relative;
   z-index: 4;
}

#fair_details{
    width: 28%;
    padding: 20px;
}
#reach_us{
    width: 28%;
    padding: 20px;
}

#footer_logo{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer_col_head{
    font-family: "Open Sans", sans-serif;
    font-size: 17px;
    text-align: left;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
}

.footer_about_desc{
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    text-align: left;
    color: white;
}

.footer_products_desc{
    text-align: left;
    color: white;
}

 .fair_date, .fair_location{
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    text-align: left;
    color: white;
 }

 .footer_socials{
     text-align: left;
 }

 .sc_ft_icon:hover{
     transform: scale(1.1);
     transition: 0.3s ease-in-out all;
 }

 .footer_reach div{
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    text-align: left;
    color: white;
    padding-top: 10px;
    padding-left: 10px;
 }

 .footer_container_bottom #footer_bottom_bar #copyright{
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    text-align: left;
    color: white;
    font-weight: 200;
 }

 .footer_container_bottom #footer_bottom_bar #developer_info{
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    text-align: left;
    color: white;
    font-weight: 200;
 }

 .footer_container_bottom #footer_bottom_bar #developer_info a{
     text-decoration: none;
     color: #ffcc00;
     font-weight: 500;
 }


 #footer_logo h1{
    font-family: "Open Sans", sans-serif;
    font-size: 50px;
    text-align: left;
    font-weight: 800;
    color: white;
 }
.scroll_top{
    position: absolute;
    right: 0;
    background-color: #ffcc00;
    padding: 10px 12px;
    bottom: 0%;
    margin-right: 8px;
    margin-bottom: 10px;
    cursor: pointer;
    
}

#company_name #bestwear{
    color: #ffcc00;
    font-family:"Open Sans", sans-serif;
    font-weight:600;
    font-size:30px; 
    padding-left:20px
}

@media screen and (max-width:1024px) {
    .footer_top_bar_left{
        width: 100%;
    }

    .footer_top_bar_right{
        display: none;
    }

    .footer_top_bar_left::after{
        display: none;
    }
}


@media screen and (max-width:768px) {
    #footer_products, #fair_details, #reach_us{
        width: 100%;
    }

    #company_name #bestwear{
        font-size:18px;
        letter-spacing: 0.05px; 
    }
}
