.what_we_do{
    background-color: rgb(249, 249, 249);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    padding-bottom: 55px;
}

.what_we_do #what_we_do_heading{
    font-family: "Open Sans", sans-serif;
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    width: fit-content;
    text-align: center;
    margin: 50px 20px;
}

.what_we_do #what_we_do_heading::before{
    content: "";
    position: absolute;
    width: 25%;
    border-bottom: 10px solid #333333;
    bottom: -50%;
    transform: translateX(100%);
}

.what_we_do #what_we_do_heading::after{
    content: "";
    position: absolute;
    width: 25%;
    border-bottom: 10px solid #ffcc00;
    bottom: -50%;
    transform: translateX(-200%);
}

.what_we_do #margin_container{
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: center;
}

@media screen and (max-width:920px) {

    .what_we_do  #margin_container{
        width: 100%;
    }
}