.header{
    min-height: 55px;
    background-color: #222222;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
    font-family: "Poppins" sans-serif;
    font-weight: 400;
    color: #b1b1b1;
    box-sizing: border-box;
}

.left_header{
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-around;
    flex: 0.4;
    font-size: 13px;
}

.left_header >div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor:pointer;
}

.left_header>div:hover{
    color: #ffcc00;
    transition: 0.3s ease-in all;
}

.fb_icon:hover{
    color:#ffcc00;
}





@media  screen and (max-width:1200px){
    .left_header{
        flex: 0.48;
        flex-flow: wrap;
    }
}
@media  screen and (max-width:950px){
    .left_header{
        flex: 0.7;
        flex-flow: wrap;
    }
}

@media  screen and (max-width:662px){
    .left_header{
        flex: 1;
        flex-flow: wrap;
    }
}
@media  screen and (max-width:450px){
    .header{
        padding-left: 0px;
        padding-right: 0px;
    }

    .left_header{
        padding-left: 20px;
        padding-right: 20px;
        justify-content: flex-start;
    }
}
