.App {
  text-align: center;
  min-width: 100vw;

}

html{
  scroll-behavior: smooth;
  
}





